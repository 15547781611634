.select-search {
    width: auto;
    position: relative;
    margin-bottom: 20px;
    font-family: var(--fontParagraph);
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

.select-search.is-disabled {
    opacity: 0.5;
}

.select-search__value {
    position: relative;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: var(--fontParagraph);
    font-size: 14px;
    text-align: left;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search__input:hover {
    border-color: #2fcc8b;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

.select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    height: 180px;
    overflow: auto;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__options {
    list-style: none;
}

.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: var(--fontParagraph);
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #2fcc8b;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}
