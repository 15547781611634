#root {
    font-family: var(--fontParagraph);
    color: #676767;
}

#mapid { height: 50vh; }

.leaflet-container{
  width: 100%;
  height: 50vh;
}